import { fetchJson } from './index';
import { apiUrl } from '../constants';
import { stringify } from 'query-string';
import formatData from './formatData';

// Define types for the customQuery method
interface CustomQueryParams {
  type: 'GET_LIST' | 'GET_ONE' | 'CREATE' | 'UPDATE' | 'DELETE';
  resource: string;
  payload: {
    filter?: Record<string, any>;
    id?: string | number;
    data?: any;
    [key: string]: any;
  };
}

// Create a customized dataProvider with the customQuery method
const customDataProvider = (dataProvider: any) => ({
  ...dataProvider,
  customQuery: ({ type, resource, payload }: CustomQueryParams) => {
    console.log('Custom query called with type:', type, 'resource:', resource, 'payload:', payload);
    
    // Handle GET_LIST type for user/settlementMapping
    if (type === 'GET_LIST' && resource === 'user/settlementMapping') {
      const { filter = {} } = payload;
      const url = `${apiUrl}/${resource}?filter=${encodeURIComponent(JSON.stringify(filter))}`;
      
      console.log('Custom query URL:', url);
      
      return fetchJson(url).then(({ json }) => {
        console.log('Response from custom query:', json);
        if (json && json.data) {
          const formattedData = Array.isArray(json.data) 
            ? json.data.map((item: any) => formatData('settlementMapping', item))
            : [formatData('settlementMapping', json.data)];
            
          return { 
            data: formattedData,
            total: formattedData.length
          };
        }
        
        return { data: [], total: 0 };
      });
    }
    
    // Handle other custom queries as needed
    return Promise.reject(new Error(`Unsupported custom query type: ${type} for resource: ${resource}`));
  }
});

export default customDataProvider; 