import {
  Datagrid,
  ExportButton,
  FilterButton,
  List,
  TextField,
  TopToolbar,
  useListContext,
  FunctionField,
  useGetList,
  useUnselectAll,
  useUpdateMany,
  useRefresh,
  useNotify,
} from 'react-admin';
import {
  Typography,
  Stack,
  MenuItem,
  Select,
  Button,
  Switch,
  IconButton,
  TextField as MuiTextField,
} from '@mui/material';
import {
  Check,
  Undo,
  Add as AddIcon,
  Remove as RemoveIcon,
} from '@mui/icons-material';
import { useState, useEffect } from 'react';

import SettlementMappingListAside from './SettlementMappingListAside';
import { settlementFilters, useGetFilters } from './SettlementMappingFilters';

const SETTLEMENT_TYPES = ['INSTANT_PAY', 'FAST_PAY', 'REGULAR_PAY'];

const UpdateSettlementType = ({
  record,
  selected,
  setSelected,
}: {
  record: any;
  selected: Array<{ type: string; value: number }> | undefined;
  setSelected: any;
}) => {
  const { selectedIds } = useListContext();
  const isSelected = selectedIds.includes(record.id);
  const currentValue =
    isSelected && selected !== undefined ? selected : record.mappingField || [];

  const getAvailableTypes = (currentIndex: number) => {
    const usedTypes = currentValue
      .map((item: any, index: number) =>
        index !== currentIndex ? item.type : null
      )
      .filter((type: any): type is string => type !== null);
    return SETTLEMENT_TYPES.filter((type) => !usedTypes.includes(type));
  };

  const handleAddType = () => {
    const availableTypes = getAvailableTypes(-1);
    if (availableTypes.length > 0) {
      setSelected([...currentValue, { type: availableTypes[0], value: 0 }]);
    }
  };

  const handleRemoveType = (index: number) => {
    const newValue = [...currentValue];
    newValue.splice(index, 1);
    setSelected(newValue);
  };

  const handleTypeChange = (index: number, type: string) => {
    const newValue = [...currentValue];
    newValue[index] = { ...newValue[index], type };
    setSelected(newValue);
  };

  const handleValueChange = (index: number, value: string) => {
    const newValue = [...currentValue];
    // Remove leading zeros and handle empty/invalid input
    const parsedValue =
      value === '' ? 0 : parseFloat(parseFloat(value).toString());
    newValue[index] = { ...newValue[index], value: parsedValue };
    setSelected(newValue);
  };

  return (
    <Stack spacing={0.5}>
      {currentValue.length === 0 && isSelected ? (
        <Stack
          direction='row'
          spacing={1}
          alignItems='center'
          sx={{
            width: 300,
            height: 32,
          }}
        >
          <IconButton
            size='small'
            onClick={handleAddType}
            sx={{
              width: 24,
              height: 24,
              borderRadius: '50%',
              border: '1px solid',
              borderColor: 'divider',
              color: 'action.active',
              '&:hover': {
                backgroundColor: 'action.hover',
              },
            }}
          >
            <AddIcon sx={{ fontSize: 16 }} />
          </IconButton>
        </Stack>
      ) : (
        currentValue.map((item: any, index: number) => {
          const availableTypes = getAvailableTypes(index);
          const isLastRow = index === currentValue.length - 1;

          return (
            <Stack
              key={index}
              direction='row'
              spacing={1}
              alignItems='center'
              sx={{
                width: 300,
                height: 32,
                '& .MuiSelect-select': { py: 0.5 },
                '& .MuiInputBase-input': { py: 0.5 },
              }}
            >
              <Select
                size='small'
                sx={{
                  width: 140,
                  height: 32,
                }}
                value={item.type}
                onChange={(e) => handleTypeChange(index, e.target.value)}
                disabled={!isSelected}
              >
                {[...availableTypes].map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
              <MuiTextField
                size='small'
                type='number'
                value={item.value === 0 ? '' : item.value}
                onChange={(e) => handleValueChange(index, e.target.value)}
                disabled={!isSelected}
                inputProps={{
                  step: 0.01,
                  min: 0,
                  style: { padding: '4px 8px' },
                }}
                sx={{
                  width: 80,
                  height: 32,
                  '& .MuiOutlinedInput-root': {
                    height: '100%',
                  },
                }}
              />
              {isSelected && (
                <>
                  <IconButton
                    size='small'
                    onClick={() => handleRemoveType(index)}
                    sx={{
                      width: 24,
                      height: 24,
                      borderRadius: '50%',
                      border: '1px solid',
                      borderColor: 'divider',
                      color: 'action.active',
                      '&:hover': {
                        backgroundColor: 'action.hover',
                      },
                      '&.Mui-disabled': {
                        borderColor: 'action.disabled',
                        color: 'action.disabled',
                      },
                    }}
                  >
                    <RemoveIcon sx={{ fontSize: 16 }} />
                  </IconButton>
                  {isLastRow && currentValue.length < SETTLEMENT_TYPES.length && (
                    <IconButton
                      size='small'
                      onClick={handleAddType}
                      sx={{
                        width: 24,
                        height: 24,
                        borderRadius: '50%',
                        border: '1px solid',
                        borderColor: 'divider',
                        color: 'action.active',
                        '&:hover': {
                          backgroundColor: 'action.hover',
                        },
                      }}
                    >
                      <AddIcon sx={{ fontSize: 16 }} />
                    </IconButton>
                  )}
                </>
              )}
            </Stack>
          );
        })
      )}
    </Stack>
  );
};

const ListActions = ({
  handleFilterWithUnmapped,
  filterWithUnmapped,
}: {
  handleFilterWithUnmapped: () => void;
  filterWithUnmapped: boolean;
}) => (
  <TopToolbar sx={{ alignItems: 'center' }}>
    <Button size='small' onClick={handleFilterWithUnmapped}>
      {'Filter with unmapped'}
      <Switch
        sx={{ ml: 1 }}
        size='small'
        checked={filterWithUnmapped}
        onChange={handleFilterWithUnmapped}
      />
    </Button>
    <FilterButton />
    <ExportButton />
  </TopToolbar>
);

const BulkActions = ({
  selected,
  setSelected,
}: {
  selected: Array<{ type: string; value: number }> | undefined;
  setSelected: any;
}) => {
  const { selectedIds } = useListContext();
  const unselectAll = useUnselectAll('settlementMapping');
  const refresh = useRefresh();
  const [updateMany] = useUpdateMany();
  const notify = useNotify();

  return (
    <Stack direction='row' alignItems='center' spacing={1}>
      <Button
        variant='outlined'
        color='inherit'
        startIcon={<Check />}
        size='small'
        disabled={!selectedIds.length}
        onClick={async () => {
          try {
            await updateMany(
              'settlementMapping',
              {
                data: { mappingField: selected || [], ids: selectedIds },
                ids: selectedIds,
              },
              {
                onSuccess: () => {
                  setSelected([]);
                  unselectAll();
                  refresh();
                  notify('Settlement types updated', { type: 'success' });
                },
                mutationMode: 'pessimistic',
              }
            );
          } catch (error) {
            notify('Error: Settlement types not updated', { type: 'error' });
          }
        }}
      >
        Update
      </Button>
      <Button
        startIcon={<Undo />}
        size='small'
        color='inherit'
        onClick={() => {
          setSelected([]);
          unselectAll();
        }}
      >
        Undo
      </Button>
    </Stack>
  );
};

const SettlementMappingList = () => {
  const [filterWithUnmapped, setFilterWithUnmapped] = useState(false);

  const filters = useGetFilters();

  return (
    <List
      sort={{ field: 'createdAt', order: 'DESC' }}
      perPage={25}
      filter={filterWithUnmapped ? { mappingField: [] } : {}}
      filters={settlementFilters(filters)}
      actions={
        <ListActions
          handleFilterWithUnmapped={() =>
            setFilterWithUnmapped(!filterWithUnmapped)
          }
          filterWithUnmapped={filterWithUnmapped}
        />
      }
      aside={<SettlementMappingListAside />}
      resource='settlementMapping'
    >
      <SettlementMappingListContent />
    </List>
  );
};

const SettlementMappingListContent = () => {
  const { selectedIds } = useListContext();
  const [settlementTypes, setSettlementTypes] = useState<
    Array<{ type: string; value: number }> | undefined
  >(undefined);

  const { data } = useGetList('settlementMapping');

  useEffect(() => {
    const selectedRecord = data?.find((record) => record.id === selectedIds[0]);

    if (selectedIds?.length === 1 && selectedRecord) {
      const mappingField = selectedRecord.mappingField || [
        { type: SETTLEMENT_TYPES[0], value: 0 },
      ];
      setSettlementTypes(mappingField);
    } else if (selectedIds?.length === 0) {
      setSettlementTypes(undefined);
    }
  }, [selectedIds]);

  return (
    <Datagrid
      bulkActionButtons={
        <BulkActions
          selected={settlementTypes}
          setSelected={setSettlementTypes}
        />
      }
      rowClick='show'
    >
      <TextField label='Payment Type' source='paymentType' />
      <TextField label='Card Type' source='cardType' />
      <TextField label='Bank Type' source='cardBankType' />
      <TextField label='Range Type' source='rangeType' />
      <FunctionField
        label='Settlement Types'
        render={(record: any) => (
          <UpdateSettlementType
            record={record}
            selected={settlementTypes}
            setSelected={setSettlementTypes}
          />
        )}
      />
    </Datagrid>
  );
};

export default SettlementMappingList;
