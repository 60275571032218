import { Card, CardContent, FormControl, InputLabel, Box, Autocomplete, TextField } from '@mui/material';
import {
  FilterList,
  FilterListItem,
  useGetList,
  SavedQueriesList,
  useListContext,
  FilterLiveSearch,
} from 'react-admin';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PaymentIcon from '@mui/icons-material/Payment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useEffect, useState } from 'react';
import {
  startOfToday,
  endOfToday,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  subDays,
  subWeeks,
  subMonths,
} from 'date-fns';

const BinListAside = () => {
  const { filterValues = {}, setFilters, displayedFilters } = useListContext();
  const { data: cardTypes = [] } = useGetList('cardTypes');
  const { data: cardBankTypes = [] } = useGetList('cardBankTypes');
  const { data: cardProviders = [] } = useGetList('cardProviders');
  const { data: banks = [], isLoading: banksLoading } = useGetList('banks', {
    pagination: { page: 1, perPage: 200 },
  });
  
  const [selectedBank, setSelectedBank] = useState<string>('');
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (filterValues?.bankId) {
      const selectedBankData = banks.find(bank => bank.dbId === filterValues.bankId);
      setSelectedBank(filterValues.bankId);
      setInputValue(selectedBankData?.name || '');
    } else {
      setSelectedBank('');
      setInputValue('');
    }
  }, [filterValues?.bankId, banks]);

  useEffect(() => {
    if (!cardBankTypes || !filterValues) return;
    
    const selectedBankType = cardBankTypes.find(
      (bankType: any) => bankType.id === filterValues?.cardBankTypeId
    );

    if (
      filterValues?.cardBankTypeId &&
      selectedBankType?.cardTypeId !== filterValues?.cardTypeId
    ) {
      const newFilters = { ...filterValues };
      delete newFilters.cardBankTypeId;
      setFilters(newFilters, displayedFilters);
    }
  }, [
    filterValues?.cardTypeId,
    filterValues?.cardBankTypeId,
    cardBankTypes,
    setFilters,
    displayedFilters,
  ]);

  const filteredCardBankTypes = cardBankTypes?.filter((bankType: any) => {
    return bankType.cardTypeId === filterValues?.cardTypeId;
  }) || [];

  const showCardBankTypes =
    !!filterValues?.cardTypeId && filteredCardBankTypes.length > 0;

  const handleBankChange = (event: any, newValue: any) => {
    const value = newValue?.dbId || '';
    setSelectedBank(value);
    setInputValue(newValue?.name || '');
    
    const newFilters = { ...filterValues };
    if (value) {
      newFilters.bankId = value;
    } else {
      delete newFilters.bankId;
    }
    
    setFilters(newFilters, displayedFilters);
  };

  return (
    <Card
      sx={{
        order: -1,
        flex: '0 0 15em',
        mr: 2,
        mt: 6,
        alignSelf: 'flex-start',
        height: '85vh',
        overflowY: 'auto',
      }}
    >
      <CardContent sx={{ pt: 1 }}>
        <FilterLiveSearch hiddenLabel />
        <SavedQueriesList />

        <Box sx={{ mt: 2, mb: 2 }}>
          <Autocomplete
            value={banks.find(bank => bank.dbId === selectedBank) || null}
            onChange={handleBankChange}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            options={banks}
            getOptionLabel={(option) => option.name || ''}
            loading={banksLoading}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Bank"
                placeholder="Search bank..."
                fullWidth
                size="small"
              />
            )}
            isOptionEqualToValue={(option, value) => option.dbId === value.dbId}
            noOptionsText="No banks found"
            clearText="Clear"
            openText="Open"
            closeText="Close"
            sx={{
              '& .MuiAutocomplete-input': {
                padding: '8px 6px',
              },
            }}
          />
        </Box>

        <FilterList label='Card Types' icon={<CreditCardIcon />}>
          {cardTypes?.map((cardType: any) => (
            <FilterListItem
              key={cardType.id}
              label={cardType.name}
              value={{ cardType: cardType.id }}
            />
          ))}
        </FilterList>

        {showCardBankTypes && (
          <FilterList label='Card Bank Types' icon={<AccountBalanceIcon />}>
            {filteredCardBankTypes.map((bankType: any) => (
              <FilterListItem
                key={bankType.id}
                label={bankType.name}
                value={{ cardBankTypeId: bankType.id }}
              />
            ))}
          </FilterList>
        )}

        <FilterList label='Card Providers' icon={<PaymentIcon />}>
          {cardProviders?.map((provider: any) => (
            <FilterListItem
              key={provider.id}
              label={provider.name}
              value={{ cardProviderId: provider.dbId }}
            />
          ))}
        </FilterList>

       

        <FilterList label='Approval Status' icon={<CheckCircleIcon />}>
          <FilterListItem
            label='Approved'
            value={{ approved: true }}
          />
          <FilterListItem
            label='Not Approved'
            value={{ approved: false }}
          />
        </FilterList>

        <FilterList label='Creation Date' icon={<AccessTimeIcon />}>
          <FilterListItem
            label='Today'
            value={{
              createdAtGTE: startOfToday().toISOString(),
              createdAtLTE: endOfToday().toISOString(),
            }}
          />
          <FilterListItem
            label='Yesterday'
            value={{
              createdAtGTE: subDays(startOfToday(), 1).toISOString(),
              createdAtLTE: subDays(endOfToday(), 1).toISOString(),
            }}
          />
          <FilterListItem
            label='This Week'
            value={{
              createdAtGTE: startOfWeek(new Date()).toISOString(),
              createdAtLTE: endOfWeek(new Date()).toISOString(),
            }}
          />
          <FilterListItem
            label='Last Week'
            value={{
              createdAtGTE: startOfWeek(subWeeks(new Date(), 1)).toISOString(),
              createdAtLTE: endOfWeek(subWeeks(new Date(), 1)).toISOString(),
            }}
          />
          <FilterListItem
            label='This Month'
            value={{
              createdAtGTE: startOfMonth(new Date()).toISOString(),
              createdAtLTE: endOfMonth(new Date()).toISOString(),
            }}
          />
          <FilterListItem
            label='Last Month'
            value={{
              createdAtGTE: startOfMonth(subMonths(new Date(), 1)).toISOString(),
              createdAtLTE: endOfMonth(subMonths(new Date(), 1)).toISOString(),
            }}
          />
        </FilterList>
      </CardContent>
    </Card>
  );
};

export default BinListAside;
