import {
  Datagrid,
  List,
  TextField,
  FilterButton,
  ExportButton,
  TopToolbar,
  ReferenceField,
  CreateButton,
  BooleanField,
  DateField,
} from 'react-admin';
import { BinFilters } from './BinFilters';
import BinListAside from './BinListAside';

const ListActions = () => (
  <TopToolbar>
    <FilterButton />
    <ExportButton />
    <CreateButton />
  </TopToolbar>
);

const BinList = () => {
  return (
    <List
      actions={<ListActions />}
      filters={BinFilters}
      aside={<BinListAside />}
    >
      <Datagrid rowClick='edit' bulkActionButtons={false}>
        <TextField source='bin' />

        <TextField source='cardTypeName' label='Card Type' />
        <TextField source='cardBankTypeName' label='Card Bank Type' />
        <TextField source='cardNetworkName' label='Card Network' />
        {/* <TextField source='cardProviderName' label='Card Provider' /> */}

        <TextField label='Bank' source='cardBankName' />
        <BooleanField source='approved' />
        <DateField source='createdAt' showTime />
        <DateField source='updatedAt' showTime />
      </Datagrid>
    </List>
  );
};

export default BinList;
