import { Card, CardContent } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import CategoryIcon from '@mui/icons-material/Category';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import {
  FilterList,
  FilterListItem,
  FilterLiveSearch,
  SavedQueriesList,
  useGetList,
} from 'react-admin';
import {
  endOfYesterday,
  startOfWeek,
  subWeeks,
  startOfMonth,
  subMonths,
  addHours,
  startOfHour,
  endOfDay,
  addDays,
  startOfToday,
} from 'date-fns';

const Aside = () => {
  // Get unique biller categories
  const { data: orders = [] } = useGetList('billpayOrders', { 
    pagination: { page: 1, perPage: 500 }
  });
  
  // Extract unique categories from orders
  const categories = Array.from(new Set(orders.map(order => order.billerCategory)))
    .filter(Boolean)
    .sort();

  return (
    <Card
      sx={{
        order: -1,
        flex: '0 0 15em',
        mr: 2,
        mt: 6,
        alignSelf: 'flex-start',
        height: '85vh',
        overflowY: 'auto',
      }}
    >
      <CardContent sx={{ pt: 1 }}>
        <FilterLiveSearch hiddenLabel />
        <SavedQueriesList />
        
        <FilterList label='Billpay Status' icon={<CheckCircleOutlineIcon />}>
          <FilterListItem
            label='Success'
            value={{
              billPayStatus: 'SUCCESS',
            }}
          />
          <FilterListItem
            label='Incomplete'
            value={{
              billPayStatus: 'INITIATED',
            }}
          />
          <FilterListItem
            label='Failed'
            value={{
              billPayStatus: 'FAILED',
            }}
          />
          <FilterListItem
            label='Refunded'
            value={{
              billPayStatus: 'REFUNDED',
            }}
          />
        </FilterList>

        {/* Category Filter */}
        <FilterList label='Category' icon={<CategoryIcon />}>
          {categories.map(category => (
            <FilterListItem
              key={category}
              label={category}
              value={{
                billerCategory: category,
              }}
            />
          ))}
        </FilterList>

        {/* Creation Date Filter */}
        <FilterList label='Creation Date' icon={<CalendarTodayIcon />}>
          <FilterListItem
            label='Today'
            value={{
              createdAtGTE: startOfToday().toISOString(),
              createdAtLTE: endOfDay(new Date()).toISOString(),
            }}
          />
          <FilterListItem
            label='Yesterday'
            value={{
              createdAtGTE: startOfToday().toISOString(),
              createdAtLTE: endOfYesterday().toISOString(),
            }}
          />
          <FilterListItem
            label='This week'
            value={{
              createdAtGTE: startOfWeek(new Date()).toISOString(),
              createdAtLTE: endOfDay(new Date()).toISOString(),
            }}
          />
          <FilterListItem
            label='Last week'
            value={{
              createdAtGTE: startOfWeek(subWeeks(new Date(), 1)).toISOString(),
              createdAtLTE: endOfDay(subWeeks(new Date(), 1)).toISOString(),
            }}
          />
          <FilterListItem
            label='This month'
            value={{
              createdAtGTE: startOfMonth(new Date()).toISOString(),
              createdAtLTE: endOfDay(new Date()).toISOString(),
            }}
          />
          <FilterListItem
            label='Last month'
            value={{
              createdAtGTE: startOfMonth(subMonths(new Date(), 1)).toISOString(),
              createdAtLTE: endOfDay(subMonths(new Date(), 1)).toISOString(),
            }}
          />
        </FilterList>

        {/* Bill Amount Filter */}
        <FilterList label='Bill Amount' icon={<CurrencyRupeeIcon />}>
          <FilterListItem
            label='Less than ₹1,000'
            value={{
              billAmountLTE: 1000,
            }}
          />
          <FilterListItem
            label='₹1,000 - ₹5,000'
            value={{
              billAmountGTE: 1000,
              billAmountLTE: 5000,
            }}
          />
          <FilterListItem
            label='₹5,000 - ₹10,000'
            value={{
              billAmountGTE: 5000,
              billAmountLTE: 10000,
            }}
          />
          <FilterListItem
            label='₹10,000 - ₹50,000'
            value={{
              billAmountGTE: 10000,
              billAmountLTE: 50000,
            }}
          />
          <FilterListItem
            label='More than ₹50,000'
            value={{
              billAmountGTE: 50000,
            }}
          />
        </FilterList>
      </CardContent>
    </Card>
  );
};

export default Aside;
