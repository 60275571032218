export enum Role {
  customer_view = 'customer_view',
  customer_edit = 'customer_edit',
  order_view = 'order_view',
  order_edit = 'order_edit',
  user_config_view = 'user_config_view',
  user_config_edit = 'user_config_edit',
  kyc_view = 'kyc_view',
  kyc_edit = 'kyc_edit',
  basic_numbers = 'basic_numbers',
  advanced_numbers = 'advanced_numbers',
}

export const roles = {
  super_admin: [
    Role.customer_view,
    Role.customer_edit,
    Role.order_view,
    Role.order_edit,
    Role.user_config_view,
    Role.user_config_edit,
    Role.kyc_view,
    Role.kyc_edit,
    Role.basic_numbers,
    Role.advanced_numbers,
  ],
  admin: [
    Role.customer_view,
    Role.customer_edit,
    Role.order_view,
    Role.order_edit,
    Role.user_config_view,
    Role.user_config_edit,
    Role.kyc_view,
    Role.kyc_edit,
    Role.basic_numbers,
  ],
  support: [
    Role.customer_view,
    Role.customer_edit,
    Role.user_config_view,
    Role.user_config_edit,
    Role.kyc_view,
    Role.kyc_edit,
    Role.order_view,
    Role.basic_numbers,
  ],
  accounts: [
    Role.customer_view,
    Role.order_view,
    Role.order_edit,
    Role.user_config_view,
    Role.kyc_view,
    Role.basic_numbers,
  ],
  accounts_support: [
    Role.customer_view,
    Role.customer_edit,
    Role.user_config_view,
    Role.user_config_edit,
    Role.kyc_view,
    Role.kyc_edit,
    Role.order_view,
    Role.order_edit,
    Role.basic_numbers,
  ],
  basic: [
    Role.customer_view,
    Role.order_view,
    Role.user_config_view,
    Role.basic_numbers,
  ],
};

export const userRoles: Record<string, any> = {
  '8892798297': roles.super_admin,
  '9449831983': roles.super_admin,
  '7019250232': roles.super_admin,
  '8802952523': roles.accounts_support,
  '7892500830': roles.support,
  '7899498856': roles.support,
  '9535082786': roles.support,
  '8050499010': roles.super_admin
};
