import * as React from 'react';
import {
  AutocompleteInput,
  DateField,
  Datagrid,
  ExportButton,
  FilterButton,
  List,
  NumberField,
  ReferenceInput,
  TextField,
  NumberInput,
  TopToolbar,
  useListContext,
  downloadCSV,
  SelectInput,
  DateTimeInput,
  FunctionField,
  SearchInput,
  TextInput,
} from 'react-admin';
import { Typography, Stack } from '@mui/material';
import CustomerReferenceField from '../customers/CustomerReferenceField';
import { Customer, Order, paymentStatusTitles } from '../types';
import jsonExport from 'jsonexport/dist';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import { WarningAmberOutlined } from '@mui/icons-material';
import OrderListAside from './OrderListAside';

export const renderPaymentStatus = (value: any) => {
  return (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      {value.billPayStatus === 'SUCCESS' && (
        <CheckCircleOutline fontSize="small" />
      )}
      {(value.billPayStatus === 'INITIATED' ||
        value.billPayStatus === 'CANCELLED') && (
        <WarningAmberOutlined fontSize="small" />
      )}
      {value.billPayStatus === 'FAILED' && (
        <WarningAmberOutlined fontSize="small" color="error" />
      )}
      <Typography variant="body2">
        {paymentStatusTitles[value.billPayStatus]}
      </Typography>
    </Stack>
  );
};

const ListActions = () => (
  <TopToolbar>
    {/* <SelectColumnsButton /> */}
    <FilterButton />
    <ExportButton />
  </TopToolbar>
);

// const OrderList = () => (
//   <List
//     filterDefaultValues={{ paymentStatus: 'RECEIVED' }}
//     sort={{ field: 'date', order: 'DESC' }}
//     perPage={25}
//     filters={orderFilters}
//     actions={<ListActions />}
//   >
//     <TabbedDatagrid />
//   </List>
// );

const paymentStatusChoices = [
  { id: 'RECEIVED', name: 'Received' },
  { id: 'SETTLEMENT_INITIATED', name: 'Settlement Initiated' },
  { id: 'SETTLEMENT_COMPLETED', name: 'Settled' },
  { id: 'FAILED', name: 'Failed' },
  { id: 'CANCELLED', name: 'Cancelled' },
  { id: 'INITIATED', name: 'Incomplete' },
];

const orderFilters = [
  <SearchInput source="q" />,
  <ReferenceInput label="Customers" source="userId" reference="customers">
    <AutocompleteInput
      optionText={(choice?: Customer) =>
        choice?.id // the empty choice is { id: '' }
          ? `${choice?.personalDetails?.firstName} ${choice?.personalDetails?.lastName}`
          : ''
      }
      sx={{ minWidth: 200 }}
    />
  </ReferenceInput>,
  <DateTimeInput label="Created After" source="createdAtGTE" />,
  <DateTimeInput label="Created Before" source="createdAtLTE" />,
  <NumberInput label="Total Above" source="settlementAmountGTE" />,
  <NumberInput label="Total Below" source="settlementAmountLTE" />,
  <NumberInput label="Bill Amount Above" source="billAmountGTE" />,
  <NumberInput label="Bill Amount Below" source="billAmountLTE" />,
  <SelectInput
    label="Payment Status"
    source="paymentStatus"
    choices={paymentStatusChoices}
  />,
  <SelectInput
    label="Bill Pay Status"
    source="billPayStatus"
    choices={[
      { id: 'SUCCESS', name: 'Success' },
      { id: 'INITIATED', name: 'Incomplete' },
      { id: 'FAILED', name: 'Failed' },
      { id: 'REFUNDED', name: 'Refunded' }
    ]}
  />,
  <TextInput
    label="Category"
    source="billerCategory"
  />,
  <DateTimeInput label="Settlement After" source="settlementByGTE" />,
  <DateTimeInput label="Settlement Before" source="settlementByLTE" />,
];

const exporter = (orders: Order[]) => {
  const ordersForExport = orders.map((order) => {
    const {
      id,
      priceBreakUp,
      // payeeInfo: { payeeNickName, payerName, ...payee },
      clientPaymentId,
      clientReferenceId,
      currency,
      guestDetail,
      updatedAt,
      ...rest
    } = order;
    return {
      ...priceBreakUp,
      // ...payee,
      ...rest,
      id,
    };
  });
  jsonExport(
    ordersForExport,
    {
      headers: ['id'], // order fields in the export
    },
    (_err: any, csv: any) => {
      downloadCSV(csv, 'orders'); // download as 'orders.csv` file
    }
  );
};

const OrderListContent = () => {
  const listContext = useListContext();
  const { filterValues, setFilters, displayedFilters, ...rest } = listContext;
  return (
    <Datagrid
      rowSx={(record, index) => ({
        opacity:
          record.billPayStatus === 'FAILED' ||
          record.billPayStatus === 'CANCELLED' ||
          record.billPayStatus === 'INITIATED'
            ? 0.5
            : 1,
        '& .MuiTableCell-root': {
          whiteSpace: 'nowrap',
        },
      })}
      bulkActionButtons={false}
      rowClick="show"
      sx={{ opacity: 1 }}
    >
      <DateField
        source="createdAt"
        showTime
        options={{
          month: 'short',
          day: 'numeric',
          hour12: true,
          hour: 'numeric',
          minute: 'numeric',
        }}
      />
      {/* <DateField
        source="paidOn"
        showTime
        label="Paid At"
        options={{
          month: 'short',
          day: 'numeric',
          hour12: true,
          hour: 'numeric',
          minute: 'numeric',
        }}
      /> */}
      <TextField source="billerCategory" label="Category" />
      {/* <TextField source="billerName" label="Biller Name" /> */}
      <FunctionField
        source="billPayStatus"
        label="Status"
        render={renderPaymentStatus}
      />
      <CustomerReferenceField link={false} />
      {/* <FunctionField
        source="priceBreakUp.serviceFeeAmount"
        label={`Profit (₹${numberToPriceFormat(totalProfit)})`}
        render={(record: any) => {
          const collected = getAmountWithoutFee(record);
          return `₹${Number(
            (collected - record.priceBreakUp.settlementAmount).toFixed(2)
          )}`;
        }}
      /> */}
      <NumberField
        label="Bill Amount"
        source="billAmount"
        options={{
          style: 'currency',
          currency: 'INR',
        }}
      />
    </Datagrid>
  );
};

const OrderList = () => {
  return (
    <>
      <List
        filterDefaultValues={{ paymentStatus: 'RECEIVED' }}
        sort={{ field: 'createdAt', order: 'DESC' }}
        perPage={25}
        filters={orderFilters}
        actions={<ListActions />}
        aside={<OrderListAside />}
        exporter={exporter}
      >
        <OrderListContent />
      </List>
    </>
  );
};

export default OrderList;
